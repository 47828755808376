import React, {useState, useRef} from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import  Ardent_logo from '../../images/ArdentPropertiesInc.jpg'
import Ardent_address from '../../images/Ardent-IsolatedFranklynAddress.jpg' 
import SignaturePad from 'react-signature-canvas'
import Popup from "reactjs-popup";
import { useScreenshot } from 'use-react-screenshot'



import './IntroSectionStyle.css'

const IntroSection = (props) => {

   
    const [state, setState] = useState({ 
        fileName: "",
        Address: "",
        tenantName: "",
        contact: "",
        currentDate: "",
        tenantAttention: "",
        landlordAttention: "",

        exterior_patio_code: "", 
        exterior_patio_comment: "", 
        exterior_deck_code: "", 
        exterior_deck_comment: "", 
        exterior_stairs_code: "", 
        exterior_stairs_comment: "", 
        exterior_light_code: "", 
        exterior_light_comment: "", 
        exterior_roof_code: "", 
        exterior_roof_comment: "",
        exterior_gutter_code: "", 
        exterior_gutter_comment: "",
        exterior_driveway_code: "", 
        exterior_driveway_comment: "", 
        exterior_siding_code: "", 
        exterior_siding_comment: "", 
        exterior_oil_code: "", 
        exterior_oil_comment: "",
        exterior_garage_code: "", 
        exterior_garage_comment: "",
        exterior_fencing_code: "", 
        exterior_fencing_comment: "",
        exterior_landscaping_code: "", 
        exterior_landscaping_comment: "",
        exterior_shed_code: "", 
        exterior_shed_comment: "",
        exterior_other_code: "", 
        exterior_other_comment: "",   
        exterior_gc_code: "", 
        exterior_gc_comment: "", 
        exterior_comment_code: "",
        exterior_comment_comment: "",


    });

    const [imageURL, setImageURL] = useState(null);


    // const getImage = (e) => {
    //     e.preventDefault();
    //     takeScreenshot(landlordSig2.current)
    //     console.log('screen shot of landlord sig taken')
    // }

    const landlordSig2 = useRef({});

    const clear = (e) => {
        e.preventDefault();
        landlordSig2.current.clear();
    }



    const save = (e) => {
        e.preventDefault();
        console.log(landlordSig2.current.getTrimmedCanvas().toDataURL("landsig.png"));
        setImageURL(landlordSig2.current.getTrimmedCanvas().toDataURL("landsig.png"));

    }
  


    const handleChange = event => {
        event.preventDefault();
        const { name, value } = event.target;
        setState(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

    return(
        <div> 

            <div className='app-div'>
        <div className='text-div'>
        <div className='logo-div'>
          <img className='logo-pic' src={ Ardent_logo }/>
        </div>
        <div className='logo-div'>
          <img className='logo-pic' src={ Ardent_address }/>
        </div>
       
        <div className='information-div'>
          <h2 className='center-text'>***ROUTINE INSPECTION ONLY***</h2>
            <p className='center-text'>
              PLEASE NOTE: It is important for both the landlord and the tenant to make ceretain that this inspection report is completed accurately.
              This provides a record of the condition of the unit and must be signed by both the tenat and the landlord.
            </p>
            <div className='condition-div'>
            <h3 className='center-text'>CONDITION CODES:</h3>
            <ul className='header-list'>
              <li className='horizontal-list'>
                <li className='horizontal-list'>G = Good</li>
                <li className='horizontal-list'>F = Fair</li>
                <li className='horizontal-list'>P = Poor</li>
                <li className='horizontal-list'>M = Missing</li>
                <li className='horizontal-list'>D = Damaged</li>
                <li className='horizontal-list'>S = Scratched</li>
                <li className='horizontal-list'>DT = Dirty</li>
                <li className='horizontal-list'>ST = Stained</li>
              </li>
            </ul>
            </div> 
          </div>
        </div>
        </div>

        
        <Form className="register-form">
        <Form.Group controlId="fileName">
          <Form.Label className='sub-header'>File Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter File Name"
            name="fileName"
            onChange={handleChange}
            className = 'header-input'
          />
        </Form.Group>
        <Form.Group controlId="Address">
          <Form.Label  className='sub-header'>Address:</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Address"
            name="Address"
            onChange={handleChange}
            className = 'header-input'
          />
        </Form.Group>
        <Form.Group controlId="tenantName">
          <Form.Label className='sub-header'>Tenant(s) Name:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Tenat(s) name"
            name="tenantName"
            onChange={handleChange}
            className = 'header-input'
          />
        </Form.Group>
        <Form.Group controlId="contact">
          <Form.Label className='sub-header'>Contact information</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter contact info"
            name="contact"
            onChange={handleChange}
            className = 'header-input'
          />
        </Form.Group>
        <Form.Group controlId="currentDate">
          <Form.Label className='sub-header'>Date</Form.Label>
          <Form.Control
            type="text"
            placeholder="YYYY/MM/DD"
            name="currentDate"
            onChange={handleChange}
            className = 'header-input'
          />
        </Form.Group>





        <div className='form-list'>
            <h2 className='white-text'>ENTRANCE & HALLWAY</h2>
            <h2 className='white-text'>CODE</h2>
            <h2 className='white-text'>COMMENTS</h2>
            
                <Form.Label className='row-title'>Door:</Form.Label>
                <Form.Select className='row-code' name='entrance_door_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option> 
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            
            
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_door_comment"
                    placeholder='Comment'
            />
                <Form.Label className='row-title'>Floors:</Form.Label>
                <Form.Select className='row-code' name='entrance_floor_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option> 
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_floor_comment"
                    placeholder='Comment'
            />
                <Form.Label className='row-title'>Walls:</Form.Label>
                <Form.Select className='row-code' name='entrance_wall_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_wall_comment"
                    placeholder='Comment'
            />
                <Form.Label className='row-title'>Ceiling:</Form.Label>
                <Form.Select className='row-code' name='entrance_ceiling_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_ceiling_comment"
                    placeholder='Comment'
            />
                <Form.Label className='row-title'>Light Fixtures:</Form.Label>
                <Form.Select className='row-code' name='entrance_light_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_light_comment"
                    placeholder='Comment'
            />
                <Form.Label className='row-title'>Smoke/Co Detectors:</Form.Label>
                <Form.Select className='row-code' name='entrance_smoke_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_smoke_comment"
                    placeholder='Comment'
            />
                <Form.Label className='row-title'>Fire Extinguihers/ Emerg. lighting:</Form.Label>
                <Form.Select className='row-code' name='entrance_fire_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_fire_comment"
                    placeholder='Comment'
            />
            <Form.Label className='row-title'>Closet(s):</Form.Label>
                <Form.Select className='row-code' name='entrance_closet_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_closet_comment"
                    placeholder='Comment'
            />
            <Form.Label className='row-title'>Stairwell:</Form.Label>
                <Form.Select className='row-code' name='entrance_stairwell_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_stairwell_comment"
                    placeholder='Comment'
            />
            <Form.Label className='row-title'>General Cleanliness:</Form.Label>
                <Form.Select className='row-code' name='entrance_gc_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_gc_comment"
                    placeholder='Comment'
            />
            <Form.Label className='row-title'>Comments:</Form.Label>
                <Form.Select className='row-code' name='entrance_comment_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </Form.Select>
            <Form.Control
                    onChange={handleChange}
                    className="row-comment"
                    name="entrance_comment_comment"
                    placeholder='Comment'
            />
        </div>



 
        <div className='form-list'>
            <h2 className='white-text'>KITCHEN</h2>
            <h2 className='white-text'>CODE</h2>
            <h2 className='white-text'>COMMENTS</h2>
            <h3 className='row-title'>Appliances:</h3>
                <select className='row-code' name='kitchen_appliances_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option> 
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_appliances_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Cabinetry & Countertops:</h3>
                <select className='row-code' name='kitchen_cabinetry_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_cabinetry_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Plumbing:</h3>
                <select className='row-code' name='kitchen_plumbing_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_plumbing_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Hood fan:</h3>
                <select className='row-code' name='kitchen_fan_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_fan_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Windows/Screens:</h3>
                <select className='row-code' name='kitchen_window_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_window_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Floors:</h3>
                <select className='row-code' name='kitchen_floor_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_floor_comment"
                    placeholder='Comment'
            />
            <h3 className='row-title'>Ceiling:</h3>
                <select className='row-code' name='kitchen_ceiling_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_ceiling_comment"
                    placeholder='Comment'
            />
            <h3 className='row-title'>General Cleanliness:</h3>
                <select className='row-code' name='kitchen_gc_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_gc_comment"
                    placeholder='Comment'
            />
            <h3 className='row-title'>Comments:</h3>
                <select className='row-code' name='kitchen_comment_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="kitchen_comment_comment"
                    placeholder='Comment'
            />
    </div>









    <div className='form-list'>
                    <h2 className='white-text'>DINING ROOM</h2>
                    <h2 className='white-text'>CODE</h2>
                    <h2 className='white-text'>COMMENTS</h2>
            <h3 className='row-title'>Floors:</h3>
                <select className='row-code' name='dining_floor_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option> 
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="dining_floor_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Walls:</h3>
                <select className='row-code' name='dining_wall_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={ handleChange }
                    className="row-comment"
                    name="dining_wall_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Ceiling:</h3>
                <select className='row-code' name='dining_ceiling_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="dining_ceiling_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Windows/Screens:</h3>
                <select className='row-code' name='dining_window_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="dining_window_comment"
                    placeholder='Comment'
            />

            <h3 className='row-title'>General Cleanliness:</h3>
                <select className='row-code' name='dining_gc_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="dining_gc_comment"
                    placeholder='Comment'
            />
            <h3 className='row-title'>Comments:</h3>
                <select className='row-code' name='dining_comment_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="dining_comment_comment"
                    placeholder='Comment'
            />
        </div>









        <div className='form-list'>
                    <h2 className='white-text'>LIVING ROOM</h2>
                    <h2 className='white-text'>CODE</h2>
                    <h2 className='white-text'>COMMENTS</h2>


            <h3 className='row-title'>Floors:</h3>
                <select className='row-code' name='living_floor_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option> 
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="living_floor_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Walls:</h3>
                <select className='row-code' name='living_wall_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="living_wall_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Ceiling:</h3>
                <select className='row-code' name='living_ceiling_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="living_ceiling_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Windows/Screens:</h3>
                <select className='row-code' name='living_windows_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="living_windows_comment"
                    placeholder='Comment'
            />

                <h3 className='row-title'>Fireplace:</h3>
                <select className='row-code' name='living_fire_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="living_fire_comment"
                    placeholder='Comment'
            />
            <h3 className='row-title'>General Cleanliness:</h3>
                <select className='row-code' name='living_gc_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="living_gc_comment"
                    placeholder='Comment'
            />
            <h3 className='row-title'>Comments:</h3>
                <select className='row-code' name='living_comment_code' onChange={handleChange}>
                <option>code</option>
                    <option value="G">G</option>
                    <option value="F">F</option>
                    <option value="P">P</option>
                    <option value="M">M</option>
                    <option value="D">D</option>
                    <option value="S">S</option>
                    <option value="DT">DT</option>
                    <option value="ST">ST</option>
                </select>
            <input
                    onChange={handleChange}
                    className="row-comment"
                    name="living_comment_comment"
                    placeholder='Comment'
            />
            </div>















            <div className='form-list'>
                <h2 className='white-text'>BATHROOM - MAIN</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>

            <h3 className='row-title'>Door:</h3>
            <select className='row-code' name='bath_m_door_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_door_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='bath_m_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Cabinetry & Countertops:</h3>
            <select className='row-code' name='bath_m_cabinetry_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_cabinetry_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Plumbing:</h3>
            <select className='row-code' name='bath_m_plumbing_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_plumbing_comment"
                placeholder='Comment'
        />
                        <h3 className='row-title'>Bathtub/Shower:</h3>
            <select className='row-code' name='bath_m_bath_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_bath_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='bath_m_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_ceiling_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Light Fixtures:</h3>
            <select className='row-code' name='bath_m_light_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_light_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Windows:</h3>
            <select className='row-code' name='bath_m_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_window_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='bath_m_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='bath_m_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_m_comment_comment"
                placeholder='Comment'
        />
    </div>

























    <div className='form-list'>
                <h2 className='white-text'>MASTER BEDROOM</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>


            <h3 className='row-title'>Door:</h3>
            <select className='row-code' name='master_door_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="master_door_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='master_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="master_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Walls:</h3>
            <select className='row-code' name='master_wall_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="master_wall_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='master_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="master_ceiling_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Closet(S):</h3>
            <select className='row-code' name='master_closet_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="master_closet_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Windows/Screens:</h3>
            <select className='row-code' name='master_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="master_winodw_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='master_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="master_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='master_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="master_comment_comment"
                placeholder='Comment'
        />
        </div>
















        <div className='form-list'>
                <h2 className='white-text'>BATHROOM - ENSUITE</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>

            <h3 className='row-title'>Door:</h3>
            <select className='row-code' name='bath_e_door_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_door_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='bath_e_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Cabintery & Countertops:</h3>
            <select className='row-code' name='bath_e_cabinetry_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_cabinetry_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Plumbing:</h3>
            <select className='row-code' name='bath_e_plumbing_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_plumbing_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Bathtub/Shower:</h3>
            <select className='row-code' name='bath_e_bath_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_bath_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='bath_e_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_ceiling_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Walls:</h3>
            <select className='row-code' name='bath_e_walls_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_walls_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Light Fixtures:</h3>
            <select className='row-code' name='bath_e_light_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_light_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Windows/Screens:</h3>
            <select className='row-code' name='bath_e_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_window_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='bath_e_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='bath_e_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bath_e_comment_comment"
                placeholder='Comment'
        />
        </div>


















        <div className='form-list'>
                <h2 className='white-text'>BEDROOM #2</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>

            <h3 className='row-title'>Door:</h3>
            <select className='row-code' name='bed2_door_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed2_door_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='bed2_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed2_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Walls:</h3>
            <select className='row-code' name='bed2_wall_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed2_wall_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='bed2_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed2_ceiling_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>Closet(S):</h3>
            <select className='row-code' name='bed2_closet_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed2_closet_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>Windows/Screens:</h3>
            <select className='row-code' name='bed2_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed2_window_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='bed2_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed2_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='bed2_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed2_comment_comment"
                placeholder='Comment'
        />
        </div>









        <div className='form-list'>
                <h2 className='white-text'>BEDROOM #3</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>

            <h3 className='row-title'>Door:</h3>
            <select className='row-code' name='bed3_door_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed3_door_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='bed3_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed3_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Walls:</h3>
            <select className='row-code' name='bed3_wall_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed3_wall_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='bed3_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed3_ceiling_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>Closet(S):</h3>
            <select className='row-code' name='bed3_closet_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed3_closet_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>Windows/Screens:</h3>
            <select className='row-code' name='bed3_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed3_window_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='bed3_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed3_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='bed3_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed3_comment_comment"
                placeholder='Comment'
        />
        </div>









        <div className='form-list'>
                <h2 className='white-text'>BEDROOM #4</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>

            <h3 className='row-title'>Door:</h3>
            <select className='row-code' name='bed4_door_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed4_door_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='bed4_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed4_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Walls:</h3>
            <select className='row-code' name='bed4_wall_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed4_wall_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='bed4_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed4_ceiling_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>Closet(S):</h3>
            <select className='row-code' name='bed4_closet_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed4_closet_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Windows/Screens:</h3>
            <select className='row-code' name='bed4_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed4_window_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='bed4_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed4_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='bed4_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="bed4_comment_comment"
                placeholder='Comment'
        />
        </div>










        <div className='form-list'>
                <h2 className='white-text'>FAMILY ROOM</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>

        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='family_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="family_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Walls:</h3>
            <select className='row-code' name='family_wall_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="family_wall_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='family_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="family_ceiling_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Light Fixtures:</h3>
            <select className='row-code' name='family_light_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="family_light_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Windows/Screens:</h3>
            <select className='row-code' name='family_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="family_window_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Fireplace:</h3>
            <select className='row-code' name='family_fire_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="family_fire_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='family_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="family_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='family_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="family_comment_comment"
                placeholder='Comment'
        />
        </div>









        <div className='form-list'>
                <h2 className='white-text'>LAUNDRY ROOM</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>

            <h3 className='row-title'>Door:</h3>
            <select className='row-code' name='laundry_door_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="laundry_door_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='laundry_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="laundry_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Walls:</h3>
            <select className='row-code' name='laundry_wall_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="laundry_wall_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='laundry_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="laundry_ceiling_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Washer/Dryer:</h3>
            <select className='row-code' name='laundry_washer_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="laundry_washer_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Windows/Screens:</h3>
            <select className='row-code' name='laundry_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="laundry_window_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='laundry_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="laundry_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='laundry_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="laundry_comment_comment"
                placeholder='Comment'
        />
        </div>













        <div className='form-list'>
                <h2 className='white-text'>BASEMENT</h2>
                <h2 className='white-text'>CODE</h2>
                <h2 className='white-text'>COMMENTS</h2>

        <h3 className='row-title'>Floors:</h3>
            <select className='row-code' name='basement_floor_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option> 
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_floor_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Walls:</h3>
            <select className='row-code' name='basement_wall_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_wall_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Ceiling:</h3>
            <select className='row-code' name='basement_ceiling_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_ceiling_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Smoke/Co Detectors:</h3>
            <select className='row-code' name='basement_smoke_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_smoke_comment"
                placeholder='Comment'
        />

            <h3 className='row-title'>Windows/Screens:</h3>
            <select className='row-code' name='basement_window_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_window_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Plumbing/Sump Pump:</h3>
            <select className='row-code' name='basement_plumbing_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_plumbing_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Built-in Vacuum:</h3>
            <select className='row-code' name='basement_vacuum_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_vacuum_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>Hot Water Tank (type & age):</h3>
            <select className='row-code' name='basement_hot_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_hot_comment"
                placeholder='Comment'
        />

        <h3 className='row-title'>Oil/Gas/Electric Furnace (filter, last service date, etc. ):</h3>
            <select className='row-code' name='basement_oil_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_oil_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>General Cleanliness:</h3>
            <select className='row-code' name='basement_gc_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_gc_comment"
                placeholder='Comment'
        />
        <h3 className='row-title'>Comments:</h3>
            <select className='row-code' name='basement_comment_code' onChange={handleChange}>
            <option>code</option>
                <option value="G">G</option>
                <option value="F">F</option>
                <option value="P">P</option>
                <option value="M">M</option>
                <option value="D">D</option>
                <option value="S">S</option>
                <option value="DT">DT</option>
                <option value="ST">ST</option>
            </select>
        <input
                onChange={handleChange}
                className="row-comment"
                name="basement_comment_comment"
                placeholder='Comment'
        />
        </div>










        <div className="form-list">

            <h2 className='white-text'>EXTERIOR</h2>
            <h2 className='white-text'>CODE</h2>
            <h2 className='white-text'>COMMENTS</h2>
                    
                    <Form.Label className='row-title'>Lower Patio(s):</Form.Label>
                    <Form.Select className='row-code' name='exterior_patio_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option> 
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_patio_comment"
                        placeholder='Comment'
                />
                <Form.Label className='row-title'>Upper Deck(s):</Form.Label>
                    <Form.Select className='row-code' name='exterior_deck_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option> 
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_deck_comment"
                        placeholder='Comment'
                />
                    <Form.Label className='row-title'>Stairs:</Form.Label>
                    <Form.Select className='row-code' name='exterior_stairs_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_stairs_comment"
                        placeholder='Comment'
                />
                    <Form.Label className='row-title'>Light Fixtures:</Form.Label>
                    <Form.Select className='row-code' name='exterior_light_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_light_comment"
                        placeholder='Comment'
                />
                    <Form.Label className='row-title'>Roof:</Form.Label>
                    <Form.Select className='row-code' name='exterior_roof_code' onChange={handleChange}>
                        <option> code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_roof_comment"
                        placeholder='Comment'
                />
                    <Form.Label className='row-title'>Gutters & Downspouts:</Form.Label>
                    <Form.Select className='row-code' name='exterior_gutter_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_gutter_comment"
                        placeholder='Comment'
                />

                    <Form.Label className='row-title'>Concrete Driveway/Walkways:</Form.Label>
                    <Form.Select className='row-code' name='exterior_driveway_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_driveway_comment"
                        placeholder='Comment'
                />
                <Form.Label className='row-title'>Siding:</Form.Label>
                    <Form.Select className='row-code' name='exterior_siding_code' onChange={handleChange}>
                        <option>code</option>    
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_siding_comment"
                        placeholder='Comment'
                />
                <Form.Label className='row-title'>Oil Tank/Heat Pump:</Form.Label>
                    <Form.Select className='row-code' name='exterior_oil_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_oil_comment"
                        placeholder='Comment'
                />
                <Form.Label className='row-title'>Garage/Carpot:</Form.Label>
                    <Form.Select className='row-code' name='exterior_garage_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_garage_comment"
                        placeholder='Comment'
                />
                            <Form.Label className='row-title'>Fencing:</Form.Label>
                    <Form.Select className='row-code' name='exterior_fencing_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_fencing_comment"
                        placeholder='Comment'
                />
                            <Form.Label className='row-title'>Landscaping:</Form.Label>
                    <Form.Select className='row-code' name='exterior_landscaping_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_landscaping_comment"
                        placeholder='Comment'
                />
                            <Form.Label className='row-title'>Shed:</Form.Label>
                    <Form.Select className='row-code' name='exterior_shed_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_shed_comment"
                        placeholder='Comment'
                />
                            <Form.Label className='row-title'>Other:</Form.Label>
                    <Form.Select className='row-code' name='exterior_other_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_other_comment"
                        placeholder='Comment'
                />
                <Form.Label className='row-title'>General Cleanliness:</Form.Label>
                    <Form.Select className='row-code' name='exterior_gc_code' onChange={handleChange}>
                        <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_gc_comment"
                        placeholder='Comment'
                />
                <Form.Label className='row-title'>Comments:</Form.Label>
                    <Form.Select className='row-code' name='exterior_comment_code' onChange={handleChange}>
                    <option>code</option>
                        <option value="G">G</option>
                        <option value="F">F</option>
                        <option value="P">P</option>
                        <option value="M">M</option>
                        <option value="D">D</option>
                        <option value="S">S</option>
                        <option value="DT">DT</option>
                        <option value="ST">ST</option>
                    </Form.Select>
                <Form.Control
                        onChange={handleChange}
                        className="row-comment"
                        name="exterior_comment_comment"
                        placeholder='Comment'
                />

            </div>


                <div>
            <h3>Areas that may require the attention of the tenant(s):</h3>
                <input
                    onChange={handleChange}
                    className="row-comment-long"
                    name="tenantAttention"
                    placeholder='Comment'
                />
            <h3>Areas that may require the attention of the landlord:</h3>
                <input
                    onChange={handleChange}
                    className="row-comment-long"
                    name="landlordAttention"
                    placeholder='Comment'
                />
        <br></br>
      
        </div>

        <div className='rental-div' >
            <Link className='rental-page-link' to={{pathname: "/rentalPdfPage", state}} >
                preview
            </Link>
        </div>    
        </Form>
    </div>
    )


};
export default IntroSection;