import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

import './index.css';
import App from './App';


import IntroSection from './components/IntroSection/IntroSection';
import RentalPdfPage from './components/RentalPdfPage/RentalPdfPage';
import Details from './components/RentalPdfPage/RentalPdfPage';

const Routing = () => {
    return(
      <Router>
        <Switch>
          <Route exact path="/" component={App} />
          <Route path="/introSection" component={IntroSection} exact = {true}/>
          <Route path="/rentalPdfPage" component={Details} />
        </Switch>
      </Router>
    )
  }


  ReactDOM.render(
    <React.StrictMode>
      <Routing />
    </React.StrictMode>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals;
