import React, { useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {pdf, PDFViewer } from '@react-pdf/renderer';
import  Pdf, { SaveBtn }  from './firstPdf';
import { Form } from 'react-bootstrap';
import  IntroSection  from './components/IntroSection/IntroSection'
import  Ardent_logo from './images/ArdentPropertiesInc.jpg'
import Ardent_address from './images/Ardent-IsolatedFranklynAddress.jpg' 
import { Link } from "react-router-dom";

import './App.css'

function App () {

  useEffect(() => {
    document.title = "Ardent Forms"
  }, [])

  //maybe redirect to a page that shows the rendered pdf
  //if (redirect)
  //    return <Redirect to={{ pathname: '/search', data: { data } }} />
  return (
    <div>
      <div className='logo-div'>
          <img className='logo-pic' src={ Ardent_logo }/>
        </div>
        <div className='logo-div'>
          <img className='logo-pic' src={ Ardent_address }/>
        </div>

      <h2>Form list</h2>
      <ul>
        <li>  
          <Link to='/introSection'>rental form</Link>
        </li>
        <li>  
          <Link to='/introSection'>rental form 2</Link>
        </li>
        <li>  
          <Link to='/introSection'>rental form 3</Link>
        </li>
        <li>  
          <Link to='/introSection'>rental form 4</Link>
        </li>
        <li>  
          <Link to='/introSection'>rental form 5</Link>
        </li>
        <li>  
          <Link to='/introSection'>rental form 6</Link>
        </li>
      </ul>
      
    </div>

  )
};



export default App;