import React, {useState} from "react";
import  Ardent_logo from './images/ArdentPropertiesInc.jpg'
import Ardent_address from './images/Ardent-IsolatedFranklynAddress.jpg' 

import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import IntroSection from "./components/IntroSection/IntroSection";


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 26,
    textAlign: "center",
    marginBottom: 10,
  },
  text: {
    fontSize: 14,
  },
  image_div: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,
  },
  header_pic: {
    width: 450,
  },
  table: { 
    display: "table", 
    width: "auto", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "33.3%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCell: { 
    margin: "auto", 
    marginTop: 5, 
    fontSize: 10 
  }
});


// Create Document Component
const MyDocument = ({ fileName, Address, tenantName, contact, currentDate}) => {

  return (
    <Document title={fileName}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style= {styles.image_div}>
          <Image style={styles.header_pic} src={Ardent_logo}></Image>
          <Image style={styles.header_pic} src={Ardent_address}></Image>

          <Text style={styles.text}>ADDRESS OF PROPERTY: {Address}</Text>
          <Text style={styles.text}>TENANT(S) NAME: {tenantName}</Text>
          <Text style={styles.text}>CONTACT INFO: {contact}</Text>
          <Text style={styles.text}>DATE: {currentDate}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const initalState = [
  {
    fileName: "",
    Address: "",
    tenantName: "",
    contact: "",
    currentDate: "",
    tenantAttention: "",
    landlordAttention: "",
  }
]


const Pdf = () => {

const handleSubmit = (e) => {
    e.preventDefault();
    updateSectionContent({
      ...sectionContent,
      [e.target.name]: e.target.value
  });

    console.log(MyDocument)
};

const [sectionContent, updateSectionContent] = useState(initalState);

return (
    <div>


      <PDFDownloadLink document={<MyDocument fileName={this.fileName} Address={this.Address} tenantName={this.tenantName} contact={this.contact} currentDate={this.currentDate} />} fileName={this.fileName}>
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <div className="d-flex justify-content-center download-button">
              <button className="btn btn-sm btn-secondary">Download</button>
            </div>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export const SaveBtn = () => {
  return <div></div>;
};

export default Pdf;
